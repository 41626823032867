import React from "react"
import { graphql } from "gatsby"
import SEO from "@components/SEO"
import CorporativeHeader from "@components/global/CorporativeHeader"
import Hero from "@components/CityPage/Hero"
import RiskSnapshot from "@components/CityPage/RiskSnapshot"
import CitySection from "@components/CityPage/CitySection"
import RiskAssessment from "@components/global/RiskAssessment"
import ActionGuide from "@components/CityPage/ActionGuide"
import Footer from "@components/global/Footer"
import "@styles/Components/city.scss"
import CityFooter from "@components/global/CorporativeFooter/CityFooter"

const CityTemplate = ({ data: { sanityCity, herobg } }) => {
  let city = sanityCity
  const meta = sanityCity.seo
    ? Object.entries(sanityCity.seo).map(([key, value]) => {
        return { name: key, content: value }
      })
    : []


  //Sanity has a typo, so instead of changing the info for all of the cities, the client asked to implement a quick fix here in the code.
  city.riskDescription[1].children[0].text = city.riskDescription[1].children[0].text.replace("continuous","contiguous")
  
  return (
    <>
      <SEO meta={meta} seo={sanityCity.seo} />
      <CorporativeHeader />
      <Hero
        city={city.city}
        state={city?.state?.state}
        title={city.title}
        herobg={herobg}
      />
      <RiskSnapshot
        city={city.city}
        state={city?.state?.state}
        riskDescription={city.riskDescription}
        images={city.imagesList}
      />
      <RiskAssessment />
      {city.content.map((section, i) => {
        return <CitySection key={i} section={section} />
      })}
      {city.actionGuide && <ActionGuide content={city.actionGuide} />}
      <RiskAssessment />
      {city.state != null && <CityFooter state={city?.state?.state} />}
      <Footer />
    </>
  )
}

export default CityTemplate

export const query = graphql`
  query($slug: String!) {
    sanityCity(slug: { current: { eq: $slug } }) {
      id
      city
      title: _rawTitle(resolveReferences: { maxDepth: 10 })
      riskDescription: _rawRiskDescription(resolveReferences: { maxDepth: 10 })
      imagesList {
        asset {
          gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF])
        }
      }
      actionGuide: _rawActionGuideContent(resolveReferences: { maxDepth: 10 })
      state {
        state
        slug {
          current
        }
      }
      content {
        ... on SanityCitySection {
          title: _rawTitle(resolveReferences: { maxDepth: 10 })
          leftBody: _rawBodyLeft(resolveReferences: { maxDepth: 10 })
          rightBody: _rawBodyRight(resolveReferences: { maxDepth: 10 })
          background
        }
      }
      seo {
        title
        description
        keywords
      }
    }

    herobg: file(relativePath: { eq: "img/city-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF])
      }
    }
  }
`
