import React from "react"
import { BaseBlockContent } from "@utils/richText"
import {GatsbyImage } from "gatsby-plugin-image"
import Report from "@components/CityPage/ReportPage"
import * as styles from "./RiskSnapshot.module.scss"

const RiskSnapshot = ({ city, state, riskDescription, images }) => {
  // const address = `${city}, ${state}, United States`

  return (
    <section className={styles.risk}>
      <div className={styles.container}>
        <div>
          <p className={styles.overline}>Risk Snapshot</p>
          <div className={styles.headline}>
            <BaseBlockContent blocks={riskDescription} />
          </div>
        </div>

        {/* <div className="city-report">
          <Report address={address} />
        </div> */}

        <div className={styles.imagesList}>
          {images.map(image => {
            return (
              <div>
                <GatsbyImage image={image.asset.gatsbyImageData} alt=""/>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default RiskSnapshot
