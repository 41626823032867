import { getGatsbyImageData } from "gatsby-source-sanity"
import { PortableText } from "@portabletext/react"
import BlockContent from '@sanity/block-content-to-react'
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { SanityRawText } from "./SanityTypes"
import { constants } from "../constants/constants"
import getYouTubeId from 'get-youtube-id'
const { SANITY_DATASET, SANITY_PROJECTID } = constants
const dataset = SANITY_DATASET
const projectId = SANITY_PROJECTID

const Figure = ({ node }) => {
  const fluidProps = getGatsbyImageData(
    node?.image?.asset?._id,
    {}, 
    { dataset, projectId }
  );
  return (
    <GatsbyImage image={fluidProps} alt={node.altText} />
  );
};
const CTAButton = ({ value }) => {
  const {
    buttonLink,
    buttonText,
    mainText,
    primaryColor,
    secondaryColor,
  } = value
  console.log("HOLAAAAA")
  return (
    <div
      className="cta-container"
      style={{ border: `1px solid ${primaryColor}`, borderRadius: 5 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 className="main-text" style={{ color: primaryColor }}>
          {mainText}
        </h2>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          onMouseOver={props => {
            props.currentTarget.style.background = secondaryColor
            props.currentTarget.style.color = primaryColor
          }}
          onMouseLeave={props => {
            props.currentTarget.style.background = primaryColor
            props.currentTarget.style.color = secondaryColor
          }}
          style={{
            background: primaryColor,
            color: secondaryColor,
            textAlign: "center",
            padding: "10px 40px",
            borderRadius: 10,
            border: `1px solid ${primaryColor}`,
          }}
          rel="noreferrer noopener"
          target="_blank"
          href={buttonLink}
        >
          {buttonText}
        </a>
      </div>
    </div>
  )
}
export const defaultSerializers = {
  block:{
    h2: props => {
      let headerId = ''
      if(typeof props.children[0] == "string"){
        headerId = props.children[0].replace(/\W/g, '').toLowerCase()
      } else {
        headerId = props.children[0].props.children[0].replace(/\W/g, '').toLowerCase()
      }
      return(<h2 id={headerId}>{props.children}</h2>)
    },
    h3: props => {
      let headerId = ''
      if(typeof props.children[0] == "string"){
        headerId = props.children[0].replace(/\W/g, '').toLowerCase()
      } else {
        headerId = props.children[0].props.children[0].replace(/\W/g, '').toLowerCase()
      }
      return(<h3 id={headerId}>{props.children}</h3>)
    },
    h4: props => {
      let headerId = ''
      if(typeof props.children[0] == "string"){
        headerId = props.children[0].replace(/\W/g, '').toLowerCase()
      } else {
        headerId = props.children[0].props.children[0].replace(/\W/g, '').toLowerCase()
      }
      return(<h4 id={headerId}>{props.children}</h4>)
    }
  },
  marks: {
    em: props => <i>{props.children}</i>, 
    strong: props => <strong>{props.children}</strong>,
    code: props => (
      <code dangerouslySetInnerHTML={{ __html: props.children }} />
    ),
    color: props => {
      return (<span style={{ color: props.mark.hex }}>{props.children}</span>)
    },
    link: ({ value, children }) => {
      return (value.blank ? <a href={value.href} target="_blank">{children}</a>
      :<a href={value.href}>{children}</a>)
    },
    internalLink: ({value, children}) => {
      if(value.item?._type == "post"){
        return (<Link to={`/blog/${value.item.slug.current}`}>{children}</Link>)
      } else{
        return (<Link to='#'></Link>)
      }
    },
    scrollAnchor: props => {
      const ref = props.value.href.replace(/\W/g, '').toLowerCase()
      return (<a onClick={() =>{
        let view = document.getElementById(ref)
        view.scrollIntoView()
      }}>{props.children[0]}</a>)
    }
  },
  types: {
    customImage: Figure,
    code: props => (
      <pre data-language={props.value.language}>
        <props.value.code>{props.value.code}</props.value.code>
      </pre>
    ),
    reference: ({ value }) => {
      return(<div>{value.reference}</div>)
    },
    ctaButton: CTAButton,

    video: props => {
      return(
        <div style={{display:"flex", justifyContent:"center"}}>
          <iframe loading="lazy" width="560" height="315" src={`https://www.youtube.com/embed/${getYouTubeId(props.value.url)}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        
    )}
  },
}

type Props = {
  blocks: SanityRawText[] | SanityRawText
  serializers?
  className?: string
}

export const BaseBlockContent: React.FC<Props> = ({
  blocks,
  serializers,
  className,
}) => {
  const serializersDict = serializers || defaultSerializers
  return (
    <PortableText
      components={serializersDict}
      value={blocks}
      className={className}
    />
  )
}
