import React from "react"
import { BaseBlockContent2 } from "@utils/richTextS"
import * as styles from "./CitySection.module.scss"

const CitySection = ({ section }) => {
  return (
    <section
      className={styles.citySection}
      style={{ backgroundColor: section?.background || "#ffffff" }}
    >
      <div className={styles.container}>
        <div className={styles.headline}>
          <BaseBlockContent2 blocks={section?.title} />
        </div>

        <div className={styles.twoCols}>
          <div className={styles.blockContent}>
            <BaseBlockContent2 blocks={section?.leftBody} />
          </div>
          <div>
            <BaseBlockContent2 blocks={section?.rightBody} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CitySection
